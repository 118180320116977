import React from "react";

const Footer = () => {
  return (
    <footer
      className="bg-gray-100 py-6"
      style={{ fontFamily: "Roboto, sans-serif" }}
    >
      <div className="container mx-auto text-center">
        {/* <div className="mb-4">
          <p className="text-lg font-semibold text-gray-800">MooresLab.AI</p>
          <p className="text-gray-600">
            For our latest product updates subscribe below
          </p>
        </div> */}
        {/* <div className="mb-4">
          <input
            type="email"
            className="px-4 py-2 border rounded-md"
            placeholder="Email"
          />
          <button className="bg-blue-600 text-white px-4 py-2 rounded-md ml-2">
            Send
          </button>
        </div> */}
        {/* <div className="flex justify-center space-x-4 mb-4">
          <a href="https://facebook.com" className="text-gray-600">Facebook</a>
          <a href="https://twitter.com" className="text-gray-600">Twitter</a>
          <a href="https://linkedin.com" className="text-gray-600">LinkedIn</a>
          <a href="https://instagram.com" className="text-gray-600">Instagram</a>
        </div> */}

        <div className="text-gray-600">
          {/* <p className="mb-2">&copy; 2023 by Placeholder</p> */}
          <p>
            512-669-5647 |{" "}
            <a
              href="mailto:support@mooreslab.ai"
              className="text-blue-600 hover:underline"
            >
              support@mooreslab.ai
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
