import React from "react";

const Hero = () => {
  return (
    <section className="flex-grow flex items-center justify-center bg-gray-50 py-20">
      <div className="text-center">

        {/* Header : MooresLab.AI */}
        <h1 className="text-4xl md:text-6xl font-bold text-blue-600 mb-4">
          MooresLab.AI
        </h1>

        {/* Sub Header :  Agentic AI for Silicon Engineering*/}
        <p className="text-xl text-gray-700 mb-8">
          Agentic AI for Silicon Engineering
        </p>

        {/* Title for Demo Video */}
        <div
          className="bg-blue-600 text-white px-6 py-3 rounded-md mb-4 w-40 mx-auto"
        >
          Demo Video
        </div>

        {/* 
         Embedded Video 
        <div className="flex justify-center px-6 space-x-4 mb-4">

            <video width="600" controls>
              <source src="/Commercial-Video.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
        </div>
        */}

        {/* Embedded  YouTube Video */}
        <iframe className="flex justify-center px-6 space-x-4 mb-4"
          width="640" 
          height="360" 
          src="https://www.youtube.com/embed/w6p53_D7vMA" 
          title="Multiply &amp; Accumulate Accelerator Demo Video." 
          frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
          referrerpolicy="strict-origin-when-cross-origin" allowfullscreen>
        </iframe>
 
        {/* Button for Contact Us */}
        <button
          className="bg-blue-600 text-white px-6 py-3 rounded-md"
          onClick={() =>
            window.open("https://calendly.com/mooreslab", "_blank")
          }
        >
          Contact Us
        </button>         

        {/* 
        <div className="flex justify-center space-x-4">

          <button
            className="bg-blue-600 text-white px-6 py-3 rounded-md"
            onClick={() =>
              window.open(
                "https://www.youtube.com/watch?v=w6p53_D7vMA",
                "_blank"
              )
            }
          >
            View Demo
          </button>
        </div>
        */}

      </div>
    </section>
  );
};

export default Hero;
