import React from "react";

const Navbar = () => {
  return (
    <nav className="bg-gray-100 p-4 shadow-md">
      <div className="container mx-auto flex justify-between items-center">
        <div className="flex items-center">
          <img src="bgrm_icon.png" alt="Icon" className="h-6 w-6 mr-2" />
          <span className="text-xl font-bold text-blue-600">MooresLab.AI</span>
          {/* <div className="hidden md:flex space-x-4">
          <a href="/" className="text-gray-700">Home</a>
          <a href="/" className="text-gray-700">Plans & Pricing</a>
          <a href="/" className="text-gray-700">Contact Us</a>
        </div> */}
        </div>

        <button
          className="bg-blue-600 text-white px-6 py-3 rounded-md"
          onClick={() =>
            window.open("https://calendly.com/mooreslab", "_blank")
          }
        >
          Book a Call
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
